export interface ValueText {
  value: number;
  text: string;
}

export enum CarType {
  MicroCar = 'microcar',
  Compact = 'compact',
  MiddleClass = 'middleClass',
  Suv = 'suv',
  SportsCar = 'sportsCar',
}

export enum ChargingPlace {
  Home = 'home',
  Road = 'road',
  Work = 'work',
}

export enum Fuel {
  Petrol = 'petrol',
  Diesel = 'diesel',
}

export enum Electricity {
  Mixed = 'mixed',
  Green = 'green',
}
export interface CarTypeListItem {
  carType: CarType;
  imageSrc: string;
  translationKey: string;
}

export interface ChargingPlaceListItem {
  chargingPlace: ChargingPlace;
  imageSrc: string;
  imageSrcChart: string;
  invertedImageSrc: string;
  translationKey: string;
}

export interface DefaultCarTypeValues {
  carPrice: number;
  consumption: number;
  insurance: number;
  maintenanceCosts: number;
  emission: number;
  productionEmission: number;
  recycling: number;
}

export interface DefaultConventionalCarFuel {
  diesel: DefaultCarTypeValues;
  petrol: DefaultCarTypeValues;
}

export interface DefaultCarTypes {
  conventional: DefaultConventionalCarFuel;
  electric: DefaultCarTypeValues;
}

export interface CalculationData {
  currency: string;
  language: string;
  timeOfUse: number[];
  annualMileage: number[];
  speedometerAnnualMileages: {
    cityTrips: number;
    shortDistance: number;
    standard: number;
    commuters: number;
    longDistance: number;
  };
  governmentalSubventions: { from: number; to: number; subvention: number }[];
  chargingStationGovernmentalSubventions: { from: number; to: number; subvention: number }[];
  acquisitionCostOfChargingStation: number[];
  electricInsurance: { from: number; to: number; cost: number }[];
  defaults: {
    emission: EmissionValues;
    electricityFromValues: ElectricityFromValues;
    data: CalculationFormData;
    microcar: DefaultCarTypes;
    compact: DefaultCarTypes;
    middleClass: DefaultCarTypes;
    suv: DefaultCarTypes;
    sportsCar: DefaultCarTypes;
  };
  fuelKeys?: string[];
}

export interface EmissionValues {
  diesel: number;
  petrol: number;
}

export interface ElectricityFromValues extends EmissionValues {
  mixed: number;
  green: number;
}

export interface FuelCosts {
  petrol: number;
  diesel: number;
}

export interface CalculationFormConventionalCarData
  extends DefaultCarTypeValues {
  fuel: Fuel | string;
  fuelCosts: number;
}

export interface CalculationFormElectricCarData extends DefaultCarTypeValues {
  acquisitionCostOfChargingStation: number;
  acquisitionCostOfChargingStationStatus: boolean;
  electricityCostsAtChargingStation: number;
  electricityCostsAtHome: number;
  electricityCostsAtWork: number;
  electricityTypeFromHome: Electricity | string;
  electricityTypeFromRoute: Electricity | string;
  electricityTypeFromWork: Electricity | string;
  percentageChargingAtHome: number;
  percentageChargingOnTheRoute: number;
  percentageChargingOnWork: number;
  changeChargingPlacePercentageStatus: boolean;
  subvention: number;
  charginStationSubvention: number;
}

export interface CalculationFormData {
  timeOfUse: number;
  annualMileage: number;
  fuelCosts: FuelCosts;
  conventional: Partial<CalculationFormConventionalCarData>;
  electric: Partial<CalculationFormElectricCarData>;
}

export interface SummaryData {
  timeOfUse: number;
  totalCosts: SummaryCalculationsWithDifference;
  fuelCosts: SummaryCalculationsWithDifference;
  otherCosts: SummaryCalculationsWithDifference;
  emission: SummaryCalculationsWithDifference;
  co2UsageEmission: SummaryCalculationsWithDifference;
  co2FullLifeCycleEmission: SummaryCalculationsWithDifference;
  breakEvenPoint: number;
  chartData: [number[], number[], number[]];
  emissionChartData: [number[], number[], number[]];
  emissionChartDataSummary: PieChartData[];
}

export interface PieChartData {
  data: number;
  color: string;
}

export interface LangData {
  lang: string;
  currency: string;
}

export interface CalculatorStore {
  carType: CarType;
  annualMileage: number;
  chargingPlaces: ChargingPlace[];
  form: CalculationFormData;
  summary: SummaryData;
  lang: LangData;
}

export enum Language {
  English = 'en',
  German = 'de',
  Polish = 'pl',
}

export interface SummaryGeneralGeneralCalculations {
  annualMileagePer100Km: number;
  conventional: {
    fuelCostsPerYear: number;
    insurancePer1Year: number;
    insurancePerXYears: number;
    maintenancePer1Year: number;
    maintenancePerXYears: number;
    totalPrice: number;
  };
  electric: {
    acquisitionCostOfChargingStation: number;
    insurancePer1Year: number;
    insurancePerXYears: number;
    electricityCostsPerYear: number;
    maintenancePer1Year: number;
    maintenancePerXYears: number;
    totalPrice: number;
  };
}

export interface SummaryCalculationsWithDifference {
  perXYears: {
    electric: number;
    conventional: number;
    difference: number;
  };
  per1Year: {
    electric: number;
    conventional: number;
    difference: number;
  };
  per100km?: {
    electric: number;
    conventional: number;
    difference: number;
  };
  atStart?: {
    electric: number;
    conventional: number;
    difference: number;
  };
}
