<button>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 64 64"
    version="1.1"
  >
    <g id="surface758967">
      <path
        id="bg"
        style="stroke: none; fill-rule: nonzero; fill-opacity: 1;"
        d="M 63.011719 32 C 63.011719 14.875 49.125 0.988281 32 0.988281 C 14.875 0.988281 0.988281 14.875 0.988281 32 C 0.988281 49.125 14.875 63.011719 32 63.011719 C 49.125 63.011719 63.011719 49.125 63.011719 32 Z M 63.011719 32 "
      />
      <path
        id="font"
        style="stroke: none; fill-rule: nonzero; fill-opacity: 1;"
        d="M 40.398438 45.9375 C 40.226562 45.6875 39.898438 45.585938 39.613281 45.726562 C 36.539062 47.175781 33.050781 48.710938 32.363281 48.851562 C 32.351562 48.835938 32.3125 48.8125 32.289062 48.761719 C 32.199219 48.636719 32.148438 48.476562 32.148438 48.261719 C 32.148438 46.523438 33.460938 41.238281 36.050781 32.550781 C 38.238281 25.25 38.488281 23.738281 38.488281 23.238281 C 38.488281 22.460938 38.1875 21.8125 37.625 21.351562 C 37.085938 20.914062 36.351562 20.6875 35.414062 20.6875 C 33.851562 20.6875 32.050781 21.273438 29.898438 22.5 C 27.8125 23.675781 25.476562 25.675781 22.976562 28.4375 C 22.773438 28.648438 22.761719 28.976562 22.925781 29.210938 C 23.085938 29.449219 23.398438 29.539062 23.675781 29.4375 C 24.550781 29.074219 28.976562 27.261719 29.625 26.863281 C 30.148438 26.539062 30.613281 26.363281 30.988281 26.363281 C 31 26.363281 31.011719 26.363281 31.023438 26.363281 C 31.023438 26.386719 31.039062 26.425781 31.039062 26.476562 C 31.039062 26.851562 30.960938 27.3125 30.800781 27.8125 C 27.039062 40.011719 25.199219 47.5 25.199219 50.6875 C 25.199219 51.8125 25.511719 52.710938 26.125 53.375 C 26.75 54.050781 27.601562 54.386719 28.636719 54.386719 C 29.75 54.386719 31.101562 53.925781 32.773438 52.960938 C 34.386719 52.039062 36.863281 50 40.324219 46.75 C 40.539062 46.523438 40.574219 46.1875 40.398438 45.9375 Z M 40.273438 10.574219 C 39.664062 9.949219 38.875 9.625 37.9375 9.625 C 36.773438 9.625 35.75 10.085938 34.914062 11 C 34.085938 11.898438 33.675781 12.988281 33.675781 14.261719 C 33.675781 15.261719 33.988281 16.101562 34.585938 16.738281 C 35.199219 17.386719 35.976562 17.710938 36.898438 17.710938 C 38.023438 17.710938 39.023438 17.226562 39.898438 16.261719 C 40.761719 15.3125 41.199219 14.210938 41.199219 12.960938 C 41.199219 12 40.886719 11.199219 40.273438 10.574219 Z M 40.273438 10.574219 "
      />
    </g>
  </svg>
</button>
