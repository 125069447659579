<h1 class="logo">
  <ng-container *transloco="let t">
    <div class="logo__wrapper">
      <img class="logo__icon" src="/assets/images/icon.svg" alt="{{ t('title') }}" />
      <span class="logo__text">{{ t('title') }}</span>
    </div>
  </ng-container>
  <ng-container *transloco="let t">
    <a class="logo__consileon-wrapper" href="https://www.consileon.de/" target="_blank">
      <img class="logo__consileon-image" src="/assets/images/consileon-logo.svg" alt="Consileon" />
      <img class="logo__consileon-image-short" src="/assets/images/consileon-logo-short.png" alt="Consileon" />
    </a>
  </ng-container>
</h1>
<router-outlet></router-outlet>

<ng-container *transloco="let t">
  <app-card class="expandable-panel">
    <header>
      <h3 (click)="onToggleExpansionPanel($event)">
        {{ t('contactForm.title') }}
        <span class="material-icons"> keyboard_arrow_down </span>
      </h3>
    </header>
    <section>
      <app-no-encapsulation>
        <app-contact-form></app-contact-form>
      </app-no-encapsulation>
    </section>
  </app-card>

  <app-card class="expandable-panel">
    <header>
      <h3 (click)="onToggleExpansionPanel($event)">
        {{ t('sources.title') }}
        <span class="material-icons"> keyboard_arrow_down </span>
      </h3>
    </header>
    <section>
      <app-no-encapsulation>
        <div class="sources" [innerHTML]="'sources.description' | transloco | safe: 'html'"></div>
      </app-no-encapsulation>
    </section>
  </app-card>

  <app-card class="expandable-panel">
    <header>
      <h3 (click)="onToggleExpansionPanel($event)">
        {{ t('disclaimer.title') }}
        <span class="material-icons"> keyboard_arrow_down </span>
      </h3>
    </header>
    <section>
      <app-no-encapsulation>
        <div class="disclaimer" [innerHTML]="'disclaimer.description' | transloco | safe: 'html'"></div>
      </app-no-encapsulation>
    </section>
  </app-card>

  <app-card class="expandable-panel">
    <header>
      <h3 (click)="onToggleExpansionPanel($event)">
        {{ t('releaseNotes.title') }}
        <span class="material-icons"> keyboard_arrow_down </span>
      </h3>
    </header>
    <section>
      <app-no-encapsulation>
        <div class="disclaimer" [innerHTML]="'releaseNotes.description' | transloco | safe: 'html'"></div>
      </app-no-encapsulation>
    </section>
  </app-card>

  <footer class="expandable-panel">
    <app-footer></app-footer>
  </footer>
</ng-container>