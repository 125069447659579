import { DOCUMENT, Location } from '@angular/common';
import { Component, Renderer2, OnInit, Inject } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import {
  NgcCookieConsentService,
  NgcInitializeEvent,
  NgcStatusChangeEvent,
} from 'ngx-cookieconsent';
import { filter, pluck } from 'rxjs/operators';

import { Language } from './interfaces';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private previousUrl: string;

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private location: Location,
    private renderer: Renderer2,
    private router: Router,
    private translocoService: TranslocoService,
    private ccService: NgcCookieConsentService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (this.previousUrl) {
          this.renderer.removeClass(document.body, this.previousUrl);
        }
        const currentUrlSlug = event.url.slice(1).replace('/', '-');
        if (currentUrlSlug) {
          this.renderer.addClass(document.body, currentUrlSlug);
        }

        this.previousUrl = currentUrlSlug;
      }
    });
  }

  ngOnInit() {
    this.trySetDefaultLang();

    const path = this.location.path();
    if (path !== '' && path !== '/') {
      this.router.navigate(['']);
    }

    this.translocoService.events$
      .pipe(
        filter((e) => e.type === 'translationLoadSuccess'),
        pluck('payload')
      )
      .subscribe(({ langName, scope }) => {
        this.setCookieBar();
      });

    this.translocoService.selectTranslate('check').subscribe(() => {
      this.setCookieBar();
    });

    this.translocoService.langChanges$.subscribe((lang: Language) => {
      this.document.documentElement.lang = lang;
      localStorage.setItem('lang', lang);
    });

    this.ccService.initialize$.subscribe((event: NgcInitializeEvent) => {
      this.blockGA(event.status);
    });

    this.ccService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {
      this.blockGA(event.status);
    });
  }

  onToggleExpansionPanel(event: MouseEvent) {
    const titleEl = event.currentTarget as HTMLElement;
    const groupEl = titleEl.closest('.expandable-panel');

    const hasClass = groupEl.classList.contains('expanded');
    if (hasClass) {
      this.renderer.removeClass(groupEl, 'expanded');
    } else {
      this.renderer.addClass(groupEl, 'expanded');
    }
  }

  private trySetDefaultLang() {
    const lang = localStorage.getItem('lang');
    if (lang) {
      this.translocoService.setActiveLang(lang);
    }
  }

  private blockGA(status) {
    if (status === 'deny') {
      window['ga-disable-G-T820JJY5BN'] = true;
    } else if (status === 'allow') {
      window['ga-disable-G-T820JJY5BN'] = false;
    }
  }

  private setCookieBar() {
    const config = this.ccService.getConfig();
    config.content = this.ccService.getConfig().content || {};
    config.content.header = this.translocoService.translate('cookie.header');
    config.content.message = this.translocoService.translate('cookie.message');
    config.content.dismiss = this.translocoService.translate('cookie.dismiss');
    config.content.deny = this.translocoService.translate('cookie.deny');
    config.content.allow = this.translocoService.translate('cookie.allow');
    config.content.policy = this.translocoService.translate('cookie.policy');
    config.content.link = '';
    config.content.href = '';
    this.ccService.destroy(); //remove previous cookie bar (with default messages)
    this.ccService.init(config); // update config with translated messages
  }
}
