<div class="speedometer-wrapper">
  <div class="speedometer" [attr.selectedIndex]="selectedItemIndex">
    <div class="items" #itemsel>
      <ng-container
        *ngFor="let item of items; let i = index; trackBy: trackByFunction"
      >
        <div
          class="items__text"
          [class.items__text--selected]="i === selectedItemIndex"
          (click)="onSelectItem(item)"
          [attr.index]="i"
        >
          <span *ngIf="i === items.length - 1">+</span>
          {{ item.value | translocoDecimal }} km
        </div>
        <div
          class="items__pointer"
          [class.items__pointer--selected]="i === selectedItemIndex"
          (click)="onSelectItem(item)"
          [attr.index]="i"
        ></div>
      </ng-container>
    </div>
    <div class="pointer">
      <div class="circle"></div>
      <div
        class="indicator"
        (mousedown)="onMouseDown($event)"
        (touchend)="onTouchEnd()"
        (touchmove)="onTouchMove($event)"
        (touchstart)="onTouchStart($event)"
      ></div>
    </div>
  </div>
</div>
<div class="speedometer-label">
  <span class="speedometer-label__value"
    ><span *ngIf="selectedItemIndex === items.length - 1">+</span
    >{{ items[selectedItemIndex]?.value | translocoDecimal }} km
  </span>
  <span
    class="speedometer-label__text"
    [innerHTML]="items[selectedItemIndex]?.text | safe: 'html'"
  ></span>
</div>
