<ng-container *transloco="let t; read: 'contactForm'">
  <form [formGroup]="form">
    <div class="form-group">
      <div class="form-control">
        <mat-form-field appearance="fill">
          <input
            matInput
            formControlName="email"
            placeholder="{{ t('email') }}"
            email
          />
        </mat-form-field>
      </div>
      <div class="form-control form-control-text-area">
        <mat-form-field appearance="fill">
          <textarea
            matInput
            placeholder="{{ t('content') }}"
            formControlName="content"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="buttons">
        <button
          [disabled]="!form.valid"
          class="calculator-button"
          type="submit"
        >
          <app-button (click)="sendEmail()" theme="white">
            {{ t('sendButton') }}
          </app-button>
        </button>
      </div>
    </div>
  </form>
</ng-container>
