import { Component } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent {
  lang$ = this.translocoService.langChanges$;

  form = new FormGroup(
    {
      email: new FormControl('', Validators.required),
      content: new FormControl('', Validators.required),
    },
    {
      updateOn: 'blur',
    }
  );

  constructor(
    private translocoService: TranslocoService,
    private snackBar: MatSnackBar,
    private httpClient: HttpClient
  ) {}

  sendEmail(): void {
    if (!this.form.valid) {
      return;
    }
    
    const formData = new FormData();

    formData.append('apiKey', '39E3AFB269FE70BC80EA6392CF8D9873073C75FF44FFF087C505F673A70D8F66AE445EFDE505290F55ABCF6F069CCA5A');
    formData.append('subject', 'Kundenanfrage Consileon E-Mobilitätsrechner');
    formData.append('from', 'automotive@consileon.de');
    formData.append('to', 'automotive@consileon.de');
    formData.append('isTransactional', 'true');
    formData.append('bodyHtml', `From ${this.form.value.email}
    <p>${this.form.value.content}</p>
    `);

    this.httpClient.post('https://api.elasticemail.com/v2/email/send', formData)
    .subscribe((resp: {success: boolean, data: {transactionid: string, messageid: string}}) => {      
      if (resp.success) {
        this.snackBar.open(
          this.translocoService.translate('contactForm.snackBar'),
          'X',
          {
            duration: 10000,
          }
        );
        this.form.reset();
      } else {
        console.error(resp);
      }  
    });
  }
}